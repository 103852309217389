<script>
import Layout from "@/router/layouts/store";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Franquias': 'Franquias',
    },
    es: {
      'Franquias': 'Franquias',
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      franchises: ''
    };
  },
  methods: {
    getList() {
      api
        .get('store/franchises')
        .then((response) => {
          if (response.data.status == 'success') {
            this.franchises = response.data.list
          }
        })
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/store">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/store">Loja Virtual</router-link></li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Franquias</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4" v-for="(fra, index) in franchises" :key="index">
        <div class="card">
          <div class="card-header rounded-top">
            <h5 class="m-0">{{ fra.name }}</h5>
          </div>
          <div class="card-body">
            <h6 class="mb-0">
              <i class="bx bx-user-pin font-size-20 align-middle"></i> {{ fra.responsible }}
            </h6>
            <h6 class="mb-0">
              <i class="bx bx-microphone font-size-20 align-middle"></i> {{ fra.phone }}
            </h6>
            <h6 class="mb-0">
              <i class="bx bx-mail-send font-size-20 align-middle"></i> {{ fra.email }}
            </h6>
            <h6 class="mt-3 mb-0 d-flex">
              <i class="bx bxs-map font-size-20 align-middle"></i>
              <div class="ml-1">
                {{ fra.company.address }},
                {{ fra.company.number }}<template>, {{ fra.company.complement }}</template><br>
                {{ fra.company.district }},
                {{ fra.company.city }}/{{ fra.company.state }},
                {{ fra.company.zipcode }}
              </div>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
